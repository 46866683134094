.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.justify-content-start {
  justify-content: flex-start;
}
