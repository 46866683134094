.live-item-container {
  align-items: center;
  justify-content: center;
  margin-top: "10px";
}

.live-item-right-side {
  display: flex;
  flex-direction: row;
  width: calc(100% - 300px);
  margin-right: 10px;
  margin-bottom: 10px;
}

.middle-container {
  /* height: calc(100vh); */
  height: calc(75vh);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;

  /* flex-wrap: wrap; */
}

.player-container {
  height: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.actions-container {
  /* padding-top: 100px; */
  display: flex;
  display: inline-block;
  flex-direction: row;
  height: calc(75vh);
  overflow-y: auto;
  width: 300px;
  /* margin-left: 10px; */
  margin-top: 60px;
  margin-right: 10px;
  padding-right: 10px;
  /* flex-wrap: wrap; */
}
