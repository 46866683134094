.layout-navigation {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: white;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26); */
  padding: 0 1rem;
  z-index: 5;
  justify-content: space-between;
  /* margin-top: 4rem; */
}

.layout-container {
  display: flex;
  flex-direction: row;
  /* padding-right: 1rem;
  padding-left: 1rem; */
}

.layout-list-container {
  height: calc(100vh - 7rem);
  flex: 2;
  /* flex-wrap: wrap; */
}

.layout-list-container-without-header {
  height: calc(100vh - 4rem);
  flex: 2;
  /* flex-wrap: wrap; */
}

.layout-selected-item-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  height: calc(100vh -7rem);
  overflow: visible;
}

.layout-selected-item-container-full-screen {
  height: calc(100vh - 7rem);
  flex: 9999;
}

.layout-right-header {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 3rem;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  /* width: 100%;
  justify-content: space-between;
  display: inline-flex; */
}
