.horizontal-slider {
  /* width: 100%;
  height: 40px;
  color: black;
  /* margin-top: 5px; */
}
.slider2-thumb {
  cursor: pointer;
  position: absolute;
  z-index: -1999;
  background: #ffffff;
  border: 5px solid black;
  margin-top: 7px;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.horizontal-slider .slider2-track {
  top: 22px;
  height: 4px;
  background-color: gray;
}

.slider2-thumb.active {
  background-color: grey;
}
.slider2-track {
  position: relative;
  background: #ddd;
}
.slider2-track.slider2-track-0 {
  background: black;
}

.horizontal-slider .slider2-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.thumb-label {
  position: absolute;
  top: -40px; /* Adjust to position above */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  font-size: 0.9rem;
}
