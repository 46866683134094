.itemListContainer {
  height: 100%;
  flex: 1;
  /* flex-wrap: wrap; */
}

.travelStatusLiveIndicator {
  display: flex;
  align-items: center;
  /* background-color: yellow; */
  justify-content: center;
  text-align: center;
}

.processongoing {
  border: 1px solid darkred;
  background-color: darkred;
  color: white;
  font-size: medium;
  font-weight: bold;
  text-align: center !important;
  border-radius: 20px;
  justify-content: center !important;
}

.processdone {
  border: 1px solid grey;
  background-color: grey;
  color: white;
  font-size: medium;
  font-weight: bold;
  text-align: center !important;
  border-radius: 20px;
  justify-content: center !important;
}
