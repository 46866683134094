.map-with-transfers-container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.shared-service-map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.shared-service-map-container-big {
  height: 45rem;
  width: 100%;
  margin-bottom: 10px;
}

.shared-service-map-big {
  height: 45rem;
  width: 100%;
}

.single-point-map-container {
  height: 35rem;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
