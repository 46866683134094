.card {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  /* max-width: 40rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
  overflow: visible;
  /* display: inline-block; */
}
