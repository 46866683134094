.main-navigation {
  position: sticky;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  /* position: fixed; */
  top: 0;
  left: 0;
  background: var(--color-accent);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 1rem 1rem;
  z-index: 5;
  gap: 10px;
  justify-content: space-between;
}

@media (max-width: 1360px) {
  .main-navigation {
    justify-content: space-between;
    padding: 1rem 1rem;
  }
}

.main-navigation-left {
  display: flex;
  align-items: center;
  gap: 14px;
}

.main-navigation-drawer-btn {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

@media (min-width: 1360px) {
  .main-navigation-drawer-btn {
    display: none;
  }
}

.main-navigation-drawer-btn span {
  display: block;
  width: 2rem;
  height: 2.5px;
  background: white;
}

.main-navigation-title {
  color: white;
  align-self: baseline;
}

@media (max-width: 1360px) {
  .main-navigation-title {
    /* display: none; */
  }
}

.main-navigation-title a {
  text-decoration: none;
  color: white;
}

.main-navigation-links-desktop {
  display: block;
  flex: 1;
}

.main-navigation-links-desktop {
  display: none;
}

@media (min-width: 1360px) {
  .main-navigation-links-desktop {
    display: block;
  }
}

.main-navigation-links-mobile {
  height: 100%;
  color: white;
  margin-top: 20px;
  margin-bottom: 0;
}

.user-circle {
  height: 25px;
  width: 25px;
}

.main-navigation-right {
  background: var(--color-accent) none repeat scroll 0 0;
  color: #eee;
  text-align: right;
  padding: 0 0;
  min-width: 200px;
}

.main-navigation-right > .dropdown {
  display: inline-block;
}

.main-navigation-right > .dropdown button {
  background: transparent;
  color: #eee;
  border: medium none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.main-navigation-right > .dropdown .dropdown-menu {
  flex-direction: column;
  display: none;
  border-radius: 0;
  border: medium none;
  background: var(--color-accent) none repeat scroll 0 0;
  color: #fff;
  margin: 0;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  min-width: 200px;
  transform: translate(0px, 64px) !important;
  text-align: center;
  gap: 15px;
}

@media (max-width: 1360px) {
  .main-navigation-right > .dropdown .dropdown-menu {
    min-width: 120px;
  }
}

.main-navigation-right > .dropdown .dropdown-menu.show {
  display: flex !important;
  padding-top: 10px;
}

.main-navigation-right > .dropdown .dropdown-menu a {
  padding: 5px 10px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-accent);
  text-align: left;
  text-decoration: none;
}

.main-navigation-right > .dropdown .dropdown-menu button {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  text-decoration: underline;
}

.main-navigation-right > .dropdown .dropdown-menu a:hover {
  background: var(--color-accent) none repeat scroll 0 0;
  background-color: var(--color-accent);
}

.main-navigation-right > .dropdown .dropdown-menu li img {
  margin-right: 5px;
}

.main-navigation-right > a svg {
  margin-right: 5px;
}

/* .gauto-header-top-area {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
} */
