.generic-input {
  margin: 0 0 1rem 0 !important;
  flex: 1;
}

.generic-input label,
.generic-input input,
.generic-input textarea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.generic-input label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.generic-input input,
.generic-input textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
  height: 38px;
  border-radius: 4px;
}

.generic-input input:focus,
.generic-input textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.generic-input--invalid label,
.generic-input--invalid p {
  /* color: red; */
}

.generic-input--invalid input,
.generic-input--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}

p {
  margin-top: 0.5rem;
}
