.footer-container {
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  align-items: center;
}

.update-container {
  justify-content: flex-start;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
}
